import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Recipe from '../routes/recipe';

import NotFound from '../routes/notFound';
import FAQ from '../routes/faq';

const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Home path="/" />
				<Recipe path="/recipe" />
				<FAQ path="/faq" />
				<NotFound default />
			</Router>
		</main>

	</div>
);

export default App;
